import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import SearchBox from '../SearchBox'

import ReactSVG from 'react-svg'

const NavBar = ({toggleNavbar, isActive}) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <div className="navbar-container ">
        <nav className="navbar navbar-expand-lg justify-content-between navbar-light border-bottom-0 bg-white" data-sticky="top">
          <div className="container">
            <div className="col-lg-2 flex-fill px-0 d-flex justify-content-between">
              {/*<img src={'img/logo2.png'} alt="OKR Tracker" height="24"/>*/}
              <Link to='/' className='navbar-brand mr-0 fade-page'>
                {/*<img src={'img/logo.svg'} alt="OKR Tracker" />*/}
                {/*<ReactSVG
                  src={'img/icons/theme/design/target.svg'}
                />*/} <b>OKR Tracker</b>
              </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                <img className="icon navbar-toggler-open" style={{height: '2.5rem'}} src={'img/icons/interface/menu.svg'} alt="menu interface icon" data-inject-svg />
                <img className="icon navbar-toggler-close" style={{height: '2.5rem'}} src={'img/icons/interface/cross.svg'} alt="cross interface icon" data-inject-svg />
              </button>
            </div>
            <div className="collapse navbar-collapse col px-0 px-lg-2 flex-fill">
              <div className="py-2 py-lg-0">
                <ul className="navbar-nav">
                  {/*<li className="nav-item">           
                    <Link to='/' className='nav-link'>
                      Features
                    </Link>
                  </li>
                  <li className="nav-item">           
                    <Link to='/pricing' className='nav-link'>
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">           
                    <Link to='/' className='nav-link'>
                      Resources
                    </Link>
                  </li>
                  <li className="nav-item">           
                    <Link to='/blog' className='nav-link'>
                      Blog
                    </Link>
                  </li>*/}
                </ul>
              </div>
            </div>
            <div className="collapse navbar-collapse justify-content-end col flex-fill px-0">
              {/*<Link to='/login' className='btn btn-outline ml-lg-12'>
                Sign In
              </Link>*/}
              <a href="https://app.okrtracker.io/earlyaccess" className="btn btn-primary ml-lg-3">Get Early Access</a>
            </div>
          </div>
        </nav>
      </div>
    )}
  />
)

export default NavBar
