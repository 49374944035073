import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import Testimonials from '../Testimonials'
import PropTypes from 'prop-types'

import ReactSVG from 'react-svg'

const HomePageTemplate = ({
  title,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  testimonials,
}) => (

    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className="has-divider">
        <div className="container hero-container">
          <div className="row align-items-center justify-content-between o-hidden">
            <div className="col-md-6 order-2 mb-3 mb-sm-0">
              <div class='embed-container'><iframe src='https://www.youtube.com/embed/L4N1q4RNi9I' frameborder='0' width='560' height='315' allowFullScreen=""></iframe></div>
              {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/L4N1q4RNi9I" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>*/}
            </div>
            <div className="col-md-6 pr-xl-5 order-1">
              <h1 className="display-4">{heading}</h1>
              <p className="lead">{description}</p>
              <p><a href="https://app.okrtracker.io/earlyaccess" className="btn btn-lg btn-primary">Get Early Access</a></p>
              <p className="text-small text-muted">We are launching soon. Be the first to try.
              </p>
              {/*<form className="d-sm-flex mb-2 mt-4">
                <button className="btn btn-lg btn-primary" type="submit">Try it for free</button>
              </form>
              <span className="text-small text-muted">14 day trial. No credit card required.
              </span>*/}
            </div>
          </div>
        </div>
        <div className="divider">
          {/*<img className="bg-primary-alt" src={'img/dividers/divider-3.svg'} alt="divider graphic" width="100%" height="96" data-inject-svg />*/}
          <ReactSVG
            src={'img/dividers/divider-3.svg'} 
            beforeInjection={svg => {
              svg.classList.add('bg-primary-alt')
            }}
          />
        </div>
      </section>

      {/*<section className="bg-primary-alt">
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <h2 className="h1">Lead your company on the path to success</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
              <div className="card card-body">
                <div className="icon-round mb-3 mb-md-4 bg-primary">
                  <ReactSVG
                    src={'img/icons/theme/code/git-4.svg'} 
                    beforeInjection={svg => {
                      svg.classList.add('icon', 'bg-primary')
                    }}
                  />
                </div>
                <h4>Team Alignment</h4>
                <p>
                  Provide a complete overview of top priority and progress. Learn from past performance to ensure your entire company always moves forward with confidence.
                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
              <div className="card card-body">
                <div className="icon-round mb-3 mb-md-4 bg-primary">
                  
                  <ReactSVG
                    src={'img/icons/theme/general/binocular.svg'} 
                    beforeInjection={svg => {
                      svg.classList.add('icon', 'bg-primary')
                    }}
                  />
                </div>
                <h4>Clarity</h4>
                <p>
                  Using a simple and powerful goal-setting framework based on OKRs enable you and your team to focus on work that deliver the most impact for your company.
                </p>
               
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="card card-body">
                <div className="icon-round mb-3 mb-md-4 bg-primary">
                  
                  <ReactSVG
                    src={'img/icons/interface/heart.svg'} 
                    beforeInjection={svg => {
                      svg.classList.add('icon', 'bg-primary')
                    }}
                  />
                </div>
                <h4>Job satisfaction</h4>
                <p>
                  No single factor has more impact on employee engagement than clearly defined goals that are written down and shared freely.
                </p>
             
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">Why OKR Tracker?</h2>
              <p className="lead">
                "Research reveals that, on average, 95% of a company's employees are unaware of, or do not understand, its strategy"<br/>
                - <a href="https://hbr.org/2005/10/the-office-of-strategy-management" target="_blank">Harvard Business Review</a>
              </p>
            </div>
          </div>
          
          
        </div>
      </section>*/}

      {/*<section>
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">What is Objectives & Key Results (OKRs)?</h2>
              <p className="lead">
                A collaborative goal settings protocol for companies, teams and individuals.
              </p>
            </div>
          </div>
          <div className="row justify-content-center mb-5">
            <div className="col-xl-11">
              <ul className="nav justify-content-center" role="tablist">
                <li className="nav-item mx-1">
                  <a className="nav-link active" href="#saas-tab-1" data-toggle="tab" role="tab" aria-controls="saas-tab-1" aria-selected="true">
                    <div className="icon-round icon-round-sm bg-primary">
                      <img className="icon bg-primary" src={'img/icons/theme/shopping/box-2.svg'} alt="box-2 icon" data-inject-svg />
                    </div>
                    Custom Dashboard
                  </a>
                </li>
                <li className="nav-item mx-1">
                  <a className="nav-link" href="#saas-tab-2" data-toggle="tab" role="tab" aria-controls="saas-tab-2" aria-selected="false">
                    <div className="icon-round icon-round-sm bg-primary">
                      <img className="icon bg-primary" src={'img/icons/theme/shopping/chart-pie.svg'} alt="chart-pie icon" data-inject-svg />
                    </div>
                    Smart Analytics
                  </a>
                </li>
                <li className="nav-item mx-1">
                  <a className="nav-link" href="#saas-tab-3" data-toggle="tab" role="tab" aria-controls="saas-tab-3" aria-selected="false">
                    <div className="icon-round icon-round-sm bg-primary">
                      <img className="icon bg-primary" src={'img/icons/theme/design/saturation.svg'} alt="saturation icon" data-inject-svg />
                    </div>
                    Dark Mode
                  </a>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </section>*/}

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6" data-aos="fade-right">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg mb-4 mb-lg-0">
                  <img src={'img/saas-2.svg'} alt="Image" />
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up">
              <h3 className="h1">Why OKR Tracker?</h3>
              <p className="lead">"Research reveals that, on average, 95% of a company's employees are unaware of, or do not understand, its strategy"<br/>
                - <a href="https://hbr.org/2005/10/the-office-of-strategy-management" target="_blank">Harvard Business Review</a></p>
              {/*<div className="row mt-5 o-hidden">
                <div className="col-sm-6" data-aos="fade-left" data-aos-delay="100">
                  <a href="#" className="card card-sm card-body flex-row align-items-center hover-shadow-3d">
                    <img className="" src={'img/logos/product/invision.svg'} alt="icon" data-inject-svg />
                    <div className="ml-3">
                      <h5 className="mb-0">Invision</h5>
                      <span>Creative Tools</span>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6" data-aos="fade-left" data-aos-delay="200">
                  <a href="#" className="card card-sm card-body flex-row align-items-center hover-shadow-3d">
                    <img className="" src={'img/logos/product/dropbox.svg'} alt="icon" data-inject-svg />
                    <div className="ml-3">
                      <h5 className="mb-0">Dropbox</h5>
                      <span>Productivity</span>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6" data-aos="fade-left" data-aos-delay="300">
                  <a href="#" className="card card-sm card-body flex-row align-items-center hover-shadow-3d">
                    <img className="" src={'img/logos/product/slack.svg'} alt="icon" data-inject-svg />
                    <div className="ml-3">
                      <h5 className="mb-0">Slack</h5>
                      <span>Communication</span>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6" data-aos="fade-left" data-aos-delay="400">
                  <a href="#" className="card card-sm card-body flex-row align-items-center hover-shadow-3d">
                    <img className="" src={'img/logos/product/trello.svg'} alt="icon" data-inject-svg />
                    <div className="ml-3">
                      <h5 className="mb-0">Trello</h5>
                      <span>Productivity</span>
                    </div>
                  </a>
                </div>
              </div>
              <a href="#" className="hover-arrow">View all Integrations</a>*/}
            </div>
          </div>
        </div>
      </section>
      <section className="has-divider bg-primary-alt mb-4">
        <div className="container pt-5">
          <div className="row justify-content-between align-items-center o-hidden">
            <div className="col-md-6 col-lg-5">

              {/*<h3 className="h1">Lead your company on the path to success</h3>

              <div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Clarity</h5>
                  <p>
                    Using a simple and powerful goal-setting framework based on OKRs enable you and your team to focus on work that deliver the most impact for your company.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Alignment</h5>
                  <p>
                    Provide a complete overview of top priority and progress. Learn from past performance to ensure your entire company always moves forward with confidence.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Job satisfaction</h5>
                  <p>
                    No single factor has more impact on employee engagement than clearly defined goals that are written down and shared freely.
                  </p>
                </div>
              </div>*/}

              <h3 className="h1" data-aos="fade-up" data-aos-delay="NaN">What is Objective & Key Results (OKRs)?</h3>
              <p className="lead mb-4" data-aos="fade-up" data-aos-delay="NaN">A simple and powerful goal-setting framework based on OKRs enable you and your team to focus on work that deliver the most impact for your company.</p>

              <div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Objectives</h5>
                  <p>
                    An Objective is a description of a goal to be achieved in the future. An Objective sets a clear direction and provides motivation.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Key Results</h5>
                  <p>
                    A Key Result is a metric with a starting value and a target value that measures progress towards an Objective.
                  </p>
                </div>
              </div>
              {/*<div className="d-flex mb-4" data-aos="fade-up" data-aos-delay="NaN">
                <div className="process-circle bg-primary"></div>
                <div className="ml-3">
                  <h5>Initiatives</h5>
                  <p>
                    No single factor has more impact on employee engagement than clearly defined goals that are written down and shared freely.
                  </p>
                </div>
              </div>*/}

              <p>OKR Tracker provide clarity and alignment around collaborative objectives so that your team can achieve more of what matters.</p>
              <a href="#">See all features →</a>

            </div>
            <div className="col-md-6" data-aos="fade-left">
              <img src={'img/saas-4.svg'} alt="Image" />
            </div>
          </div>
        </div>
        <div className="divider">
          {/*<img src={'img/dividers/divider-3.svg'} alt="graphical divider" data-inject-svg />*/}
          <ReactSVG
            src={'img/dividers/divider-3.svg'} 
          />
        </div>
      </section>
      {/*<section>
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">How we stack up</h2>
              <p className="lead">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10" data-aos="fade-up">
              <table className="table pricing-table pricing-table-competitors">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" className="bg-primary-alt">
                      <img src={'img/logo.svg'} alt="Image" />
                    </th>
                    <th scope="col">
                      <img src={'img/dropbox.svg'} alt="Image" />
                    </th>
                    <th scope="col">
                      <img src={'img/invision.svg'} alt="Image" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Integrations</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Segmentation</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">White Labelling</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Document Signing</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Personalised Support</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Storage</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Funnels</span>
                      <span className="text-small text-muted"></span>
                    </th>
                    <td className="bg-primary-alt">
                      <div className="icon-round icon-round-xs bg-success">
                        <img className="icon bg-light" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-primary-3-alt">
                        <img className="icon bg-primary-3" src={'img/icons/interface/check.svg'} alt="check interface icon" data-inject-svg />
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="text-right">
                <a href="#" className="hover-arrow">Explore all features</a>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-6">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div data-flickity='{ "imagesLoaded": true, "wrapAround": true }'>
                <div className="carousel-cell mx-md-4">
                  <div className="card card-body flex-row py-4">
                    <img src={'img/avatars/male-2.jpg'} alt="Avatar" className="avatar avatar-lg" />
                    <div className="ml-3">
                      <h4>
                        &ldquo;The team were only too happy to help me getting started.&rdquo;
                      </h4>
                      <div className="avatar-author d-block">
                        <h6>Anil Kumar</h6>
                        <span>Designer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-cell mx-md-4">
                  <div className="card card-body flex-row py-4">
                    <img src={'img/avatars/male-1.jpg'} alt="Avatar" className="avatar avatar-lg" />
                    <div className="ml-3">
                      <h4>
                        &ldquo;Once again, my expectations have been surpassed – Bravo!&rdquo;
                      </h4>
                      <div className="avatar-author d-block">
                        <h6>Benjamin Cameronr</h6>
                        <span>Designer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-cell mx-md-4">
                  <div className="card card-body flex-row py-4">
                    <img src={'img/avatars/female-4.jpg'} alt="Avatar" className="avatar avatar-lg" />
                    <div className="ml-3">
                      <h4>
                        &ldquo;What usually takes at least 2 or 3 weeks was reduced to 4 days.&rdquo;
                      </h4>
                      <div className="avatar-author d-block">
                        <h6>Annabelle Porter</h6>
                        <span>Designer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <section className="p-0">
      <div className="container mb-lg-n7 position-relative">

        <div class="row text-center mb-5">
          <div class="col-xl-12 col-lg-12">
            <h2 class="display-4 mx-xl-6">Said about OKRs</h2>
            <p class="lead">
              What leaders say about the impact of the OKR framework
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;OKRs have kept me and the company on time and on track when it mattered the most.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/male-1.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>Larry Page</h6>
                  <span>Co-founder of Google</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;OKRs are not a silver bullet. They can not substitute for sound judgement, strong leadership, or a creative workplace culture. But if those fundamentals are in place OKRs can guide you to the mountaintop.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/male-4.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>John Doerr</h6>
                  <span>Investor at Kleiner Perkins</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex" data-aos="fade-up" data-aos-delay="NaN">
            <div className="card card-body shadow-sm">
              {/*<div className="d-flex mb-3">
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
                <img className="icon bg-warning" src={'img/icons/interface/star.svg'} alt="star interface icon" data-inject-svg />
              </div>*/}
              <div className="my-md-2 flex-grow-1">
                <h4>
                  &ldquo;The OKR framework cultivate the madness and the chemistry contained inside it. It gives us an environment for risk and trust where failing is not a firing offence. When you have that sort of structured environment and the right people - magic is around the corner.&rdquo;
                </h4>
              </div>
              <div className="avatar-author align-items-center">
                {/*<img src={'img/avatars/female-4.jpg'} alt="Avatar" className="avatar" />*/}
                <div className="ml-2">
                  <h6>Bono</h6>
                  <span>U2</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>


      {/*<section className="bg-primary text-light has-divider">
        <div className="divider flip-y">
          <ReactSVG
            src={'img/dividers/divider-3.svg'} 
          />
        </div>
        <div className="container">
          <div className="row justify-content-center mb-0 mb-md-3">
            <div className="col-xl-6 col-lg-8 col-md-10 text-center">
              <h3 className="h2">Power your team with OKR Tracker</h3>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <form className="d-md-flex mb-3 justify-content-center">
                <input type="email" className="mx-1 mb-2 mb-md-0 form-control form-control-lg" placeholder="Email Address" />
                <button className="mx-1 btn btn-primary-3 btn-lg" type="submit">Get Started</button>
              </form>
              <div className="text-small text-muted mx-xl-6">
                Add some helper text here to explain the fine print details of your product or service.
              </div>
            </div>
          </div>
        </div>
      </section>*/}

      {/*
      <Offerings gridItems={offerings.blurbs} />
      <h2 className='has-text-weight-semibold is-size-2'>Testimonials</h2>
      <Testimonials testimonials={testimonials} />
      */}

   </div>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,

}

export default HomePageTemplate
